import React from 'react';
import { ImSpinner8 } from 'react-icons/im';

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-ivory-200">
      <div className="text-center">
        <ImSpinner8 className="mx-auto text-terracotta-500 text-5xl animate-spin" />
      </div>
    </div>
  );
};

export default LoadingScreen;

import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { RxCopy } from 'react-icons/rx';
import { FaRegCircleCheck } from 'react-icons/fa6';

const MessageContent = ({ content, isUser, userPicture }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    });
  };

  // Convert single line breaks to <br/> tags
  const formattedContent = content.replace(/\n/g, '  \n');

  const classNames = () => {
    if (isUser) {
      return 'relative max-w-[560px] inline-block py-[6px] pl-[16px] pr-[50px] rounded-[16px] bg-gradient-to-b from-ivory-250 to-ivory-350 border-[0.5px] border-ivory-400 shadow-sm break-words';
    } else {
      return `${
        content ? 'opacity-100' : 'opacity-0'
      } w-full py-[10px] px-[20px] inline-block rounded-[16px] bg-gradient-to-b from-ivory-50 to-ivory-150 border-[0.5px] border-ivory-300 shadow-sm transition duration-1000`;
    }
  };

  return (
    <div className={classNames()}>
      {isUser && (
        <img
          src={userPicture}
          alt="Avatar"
          className="absolute top-[12px] right-[10px] w-7 h-7 rounded-full border-[0.5px] border-ivory-400"
        />
      )}
      <div
        className="prose prose-sm sm:prose max-w-none prose-pre:p-0 prose-pre:m-0 prose-pre:bg-transparent prose-pre:border-none custom-markdown"
        style={{ fontSize: isUser ? '15px' : '16px' }}
      >
        <ReactMarkdown
          components={{
            code({ node, className, children, ...props }) {
              if (isUser) {
                // For user messages, return plain text
                return <span className="text-charcoal-500">{children}</span>;
              }

              const match = /language-(\w+)/.exec(className || '');
              const codeString = String(children).replace(/\n$/, '');

              // Check if this is inline code
              const isInlineCode = 'position' in node.children[0];

              if (!isInlineCode) {
                return (
                  <div className="relative rounded-[0.75em] overflow-hidden my-1">
                    <div className="flex justify-between items-center bg-gradient-to-b from-ivory-300 to-ivory-400 text-ivory-50 pl-4 pr-3 py-1 text-sm">
                      <span className="font-mono text-charcoal-500 text-xs">
                        {match ? match[1] : 'code'}
                      </span>
                      <button
                        onClick={() =>
                          handleCopy(codeString, node.position.start.line)
                        }
                        className="px-2 py-1 flex items-center text-xs text-charcoal-500 hover:bg-ivory-300 rounded transition duration-200 ease-in-out"
                      >
                        {copiedIndex === node.position.start.line ? (
                          <FaRegCircleCheck className="mr-1" />
                        ) : (
                          <RxCopy className="mr-1" />
                        )}
                        Copy
                      </button>
                    </div>
                    <SyntaxHighlighter
                      style={vscDarkPlus}
                      language={match ? match[1] : 'text'}
                      PreTag="div"
                      {...props}
                      customStyle={{
                        margin: 0,
                        padding: '1.25em',
                        fontSize: '0.9em',
                        backgroundColor: '#292929',
                        borderRadius: '0 0 0.75em 0.75em',
                      }}
                    >
                      {codeString}
                    </SyntaxHighlighter>
                  </div>
                );
              } else {
                return (
                  <code className="text-charcoal-500" {...props}>
                    {children}
                  </code>
                );
              }
            },
            p: ({ children }) => (
              <p style={{ margin: '0.5em 0', padding: 0, color: '#333333' }}>
                {children}
              </p>
            ),
            li: ({ children }) => (
              <li style={{ margin: '0.25em 0', padding: 0, color: '#333333' }}>
                {children}
              </li>
            ),
            ul: ({ children }) => (
              <ul
                style={{
                  margin: '0.5em 1em 0.5em 2em',
                  padding: 0,
                  listStyleType: 'disc',
                  color: '#333333',
                }}
              >
                {children}
              </ul>
            ),
            ol: ({ children }) => (
              <ol
                style={{
                  margin: '0.5em 1em 0.5em 2em',
                  padding: 0,
                  color: '#333333',
                }}
              >
                {children}
              </ol>
            ),
            a: ({ href, children }) => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
            br: () => <br />,
          }}
        >
          {formattedContent}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default MessageContent;

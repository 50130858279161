import React from 'react';
import { Link } from 'react-router-dom';

const ComparisonSection = () => {
  return (
    <>
      <div className="relative max-w-5xl mx-auto px-2 flex flex-col md:flex-row md:space-x-10 justify-center">
        <div className="w-full md:w-[350px] md:h-[450px] mb-8 md:mb-0 bg-gradient-to-br from-ivory-200 to-ivory-300 rounded-lg p-6 shadow">
          <h2 className="text-3xl font-bold mb-6">
            Why building a business is tough
          </h2>
          <ul className="h-[200px] flex flex-col justify-between text-lg">
            <li className="flex items-start">
              <span className="font-medium">Cluelessness about next steps</span>
            </li>
            <li className="flex items-start">
              <span className="font-medium">
                Lack of a structured process to follow
              </span>
            </li>
            <li className="flex items-start">
              <span className="font-medium">
                Time and resources wasted on unvalidated ideas
              </span>
            </li>
          </ul>
        </div>

        <div className="hidden mt-[126px] space-y-8 md:flex flex-col">
          <img
            src="/assets/arrow.png"
            alt="arrow"
            loading="lazy"
            className="w-10 -rotate-90"
          />
          <img
            src="/assets/arrow.png"
            alt="arrow"
            loading="lazy"
            className="w-10 -rotate-90"
          />
          <img
            src="/assets/arrow.png"
            alt="arrow"
            loading="lazy"
            className="w-10 -rotate-90"
          />
        </div>

        <div className="w-full md:w-[350px] md:h-[450px] bg-gradient-to-br from-ivory-200 to-ivory-300 rounded-lg p-6 shadow">
          <h2 className="text-3xl font-bold mb-6">How Buildpad empowers you</h2>
          <ul className="h-[200px] flex flex-col justify-between text-lg">
            <li className="flex items-start">
              <span className="font-medium">
                AI guidance for clear direction and actionable steps
              </span>
            </li>
            <li className="flex items-start">
              <span className="font-medium">
                Structured, step-by-step business-building process
              </span>
            </li>
            <li className="flex items-start">
              <span className="font-medium">
                Idea validation tools to ensure market demand
              </span>
            </li>
          </ul>
        </div>

        <div className="absolute -bottom-20 right-1/2 translate-x-1/2 space-x-6 md:space-x-24 flex justify-center items-center">
          <img
            src="/assets/confused-man.png"
            alt="Confused entrepreneur"
            loading="lazy"
            className="w-24 md:w-48"
          />
          <img
            src="/assets/arrow.png"
            alt="arrow"
            loading="lazy"
            className="w-10 md:w-20 translate-y-2 md:translate-y-6 -rotate-90"
          />
          <img
            src="/assets/business-man.png"
            alt="Empowered entrepreneur"
            loading="lazy"
            className="w-24 md:w-48"
          />
        </div>
      </div>
      <div className="mt-28 flex justify-center">
        <Link
          to="/signup"
          className="px-8 py-4 text-lg text-ivory-50 bg-charcoal-500 hover:bg-charcoal-700 rounded-lg transition duration-200 ease-in-out"
        >
          Start building with confidence
        </Link>
      </div>
    </>
  );
};

export default ComparisonSection;

import React from 'react';
import { Link } from 'react-router-dom';
import GoogleAuthButton from '../components/GoogleAuthButton';

const SignUp = () => {
  return (
    <div className="min-h-screen mx-auto bg-ivory-200 text-charcoal-500 flex items-center justify-center px-4">
      <div className="max-w-md w-full lg:w-[400px] space-y-8 bg-ivory-50 p-10 rounded-xl shadow-lg">
        <div className="flex flex-col items-center">
          <Link to="/" className="text-4xl font-bold text-terracotta-500">
            Buildpad
          </Link>
          <h2 className="mt-6 text-3xl font-extrabold text-charcoal-600 text-center">
            Create an account
          </h2>
          <p className="mt-2 text-sm text-center">
            Join us and start building today
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <GoogleAuthButton />
          </div>
        </div>
        <div className="text-center text-sm">
          <p>
            Already have an account?{' '}
            <Link
              to="/signin"
              className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out"
            >
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, withTokenRefresh } from '../api/axios';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { FaGoogle } from 'react-icons/fa';

const GoogleAuthButton = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = withTokenRefresh(
      () => api.get('/auth/check-auth'),
      (response) => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      },
      (error) => {
        console.error('Authentication failed:', error);
        setIsAuthenticated(false);
      }
    );

    checkAuthStatus();
  }, []);

  const authenticateWithGoogle = async (userInfo) => {
    try {
      const response = await api.post('/auth/google-auth', userInfo);
      if (response.status === 200) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      if (error.response) {
        throw new Error(
          error.response.data.message || 'Google authentication failed!'
        );
      } else if (error.request) {
        throw new Error('Network error: Unable to authenticate with Google');
      } else {
        throw new Error('Error: ' + error.message);
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const userInfo = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${codeResponse.access_token}` },
          }
        );
        await authenticateWithGoogle(userInfo.data);
      } catch (error) {
        console.error('Error during Google authentication:', error.message);
        // Might want to set some state here to show an error message to the user
      }
    },
    onError: (error) => console.log('Google Authentication Failed:', error),
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [navigate, isAuthenticated]);

  return (
    <button
      onClick={login}
      className="plausible-event-name=continue-with-google w-full h-10 px-6 flex items-center justify-center text-ivory-50 font-medium bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200 ease-in-out"
    >
      <FaGoogle className="mr-2" />
      Continue with Google
    </button>
  );
};

export default GoogleAuthButton;

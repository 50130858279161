import React from 'react';
import Footer from '../components/Footer';

const TermsOfService = () => {
  return (
    <div className="w-full min-h-screen overflow-y-auto">
      <div className="max-w-3xl mx-auto py-12 px-8">
        <h1 className="text-3xl font-bold text-charcoal-500 mb-8">
          Terms of Service
        </h1>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            1. Acceptance of Terms
          </h2>
          <p className="text-charcoal-500">
            By accessing or using Buildpad, you agree to be bound by these Terms
            of Service. If you disagree with any part of the terms, you may not
            access the service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            2. Description of Service
          </h2>
          <p className="text-charcoal-500">
            Buildpad is an AI-powered platform providing guidance and structured
            processes for entrepreneurs and startup founders. Our service
            includes tools for idea validation, business planning, and strategic
            decision-making.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            3. User Accounts
          </h2>
          <p className="text-charcoal-500">
            When you create an account with us, you must provide information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of your account on our service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            4. User Conduct
          </h2>
          <p className="text-charcoal-500">You agree not to use Buildpad to:</p>
          <ul className="list-disc pl-5 mt-2 text-charcoal-500">
            <li>Violate any applicable laws or regulations</li>
            <li>Infringe upon the rights of others</li>
            <li>
              Transmit any material that is unlawful, offensive, or malicious
            </li>
            <li>Impersonate any person or entity</li>
            <li>Interfere with or disrupt the service or servers</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            5. Intellectual Property
          </h2>
          <p className="text-charcoal-500">
            The service and its original content, features, and functionality
            are and will remain the exclusive property of Buildpad and its
            licensors. The service is protected by copyright, trademark, and
            other laws of both Hungary and foreign countries.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            6. Termination
          </h2>
          <p className="text-charcoal-500">
            We may terminate or suspend your account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach the Terms. Upon termination, your right to
            use the service will immediately cease.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            7. Limitation of Liability
          </h2>
          <p className="text-charcoal-500">
            In no event shall Buildpad, nor its directors, employees, partners,
            agents, suppliers, or affiliates, be liable for any indirect,
            incidental, special, consequential or punitive damages, including
            without limitation, loss of profits, data, use, goodwill, or other
            intangible losses, resulting from your access to or use of or
            inability to access or use the service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            8. Changes
          </h2>
          <p className="text-charcoal-500">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. We will provide at least 30 days' notice
            prior to any new terms taking effect. What constitutes a material
            change will be determined at our sole discretion.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            9. Governing Law
          </h2>
          <p className="text-charcoal-500">
            These Terms shall be governed and construed in accordance with the
            laws of Hungary, without regard to its conflict of law provisions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            10. Contact Information
          </h2>
          <p className="text-charcoal-500">
            For any questions about these Terms, please contact us at
            hello@buildpad.com.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;

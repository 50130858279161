import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-charcoal-200">
      <button
        className="w-full py-5 px-3 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-2 text-lg font-medium text-charcoal-600">
          {question}
        </span>
        {isOpen ? (
          <FaMinus className="text-charcoal-400" />
        ) : (
          <FaPlus className="text-charcoal-400" />
        )}
      </button>
      {isOpen && <div className="pb-5 px-3 text-charcoal-500">{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: 'What is Buildpad?',
      answer:
        'Buildpad is a business-building platform that combines a structured process with AI to make entrepreneurship easier. It guides you through each step of creating and growing your business.',
    },
    {
      question: 'What AI is Buildpad using?',
      answer:
        'Buildpad will always use the most intelligent and capable AI available. At this moment that is Claude Sonnet 3.5.',
    },
    {
      question: 'How does Buildpad help with idea validation?',
      answer:
        "Buildpad uses keywords to scan Reddit's API, finding relevant posts and discussions. This provides real-world data on different ideas and needs, helping you validate your concepts effectively.",
    },
    {
      question: 'Is my project data secure on Buildpad?',
      answer:
        'Yes, your data is secure. We use industry-standard encryption to protect all project information on Buildpad.',
    },
    {
      question: 'Can I bring my existing projects into Buildpad?',
      answer:
        "Absolutely. We have a smooth onboarding process for people with existing businesses who want to transition to Buildpad. It's simple and designed to get you up and running quickly.",
    },
    {
      question: "How does Buildpad's AI guidance work in practice?",
      answer:
        "Buildpad's AI works through chats, which are tailored to fulfill the goals of each business phase - from idea validation to running a successful company. The AI guides you through a structured process, providing actionable steps at every stage. As you interact with the AI it learns more about your business and is able to provide personalized advice that you wouldn't be able to get anywhere else. It's like having a super intelligent business companion.",
    },
    {
      question: 'What features do you have planned for Buildpad?',
      answer:
        "Once the Beta phase is over we'll be implementing more ways for you and the AI to get real-world data to help out with all the different phases of building your business. We'll also implement a note-taking system because we know how important it is to have clear and structured note-taking. Finally, we have some ideas to integrate better with existing services you may use. Our goal is to give you everything you need to build a successful business with Buildpad.",
    },
  ];

  return (
    <div id="faq" className="max-w-3xl mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-8 text-charcoal-700">
        Frequently asked questions
      </h2>
      <div className="space-y-2">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-screen mx-auto bg-ivory-200 text-charcoal-500 flex items-center justify-center px-4">
      <div className="max-w-md w-full text-center">
        <h1 className="text-9xl font-bold text-terracotta-500">404</h1>
        <h2 className="text-4xl font-bold text-charcoal-600 mt-4">
          Page Not Found
        </h2>
        <p className="text-xl mt-4">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="inline-block mt-8 px-6 py-3 bg-terracotta-500 text-ivory-50 font-semibold rounded-lg shadow-md hover:bg-terracotta-600 transition duration-200 ease-in-out"
        >
          Go to Homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFound;

import React from 'react';
import MessageContent from './MessageContent';

const ChatGPTvsBuildpad = () => {
  const chatgptAnswer = `Finding the optimal price for your service involves balancing profitability with customer demand. Here's a step-by-step approach you can follow:

1. Understand Costs
   • Fixed Costs: These are costs that do not change with the number of services provided (e.g., rent, salaries).
   • Variable Costs: Costs that vary with the level of service provided (e.g., materials, labor).
   • Break-even Price: Calculate the minimum price needed to cover all costs using:
     Break-even Price = (Total Fixed Costs / Number of Units) + Variable Cost per Unit

2. Analyze Competitors
   • Market Research: Look at how competitors price similar services. This gives a baseline for what customers might be willing to pay.
   • Positioning: Determine if your service is premium, standard, or budget compared to competitors, and adjust pricing accordingly.

3. Assess Customer Value Perception
   • Surveys and Feedback: Collect data on how much customers value your service.

...`;

  const buildpadAnswer = `David, finding the optimal price for your Tinder Roast service is crucial for maximizing revenue while remaining competitive. Here are some strategies to help you determine the best price point:

1. Cost-plus pricing: Start by calculating your costs (time, video production, hosting, etc.) and add a desired profit margin. This gives you a baseline.

2. Competitor analysis: Research similar services and their pricing. While your service is unique, this can give you a ballpark range.

3. Value-based pricing: Consider the value you're providing to customers. If your roasts significantly improve their dating success, you might be able to charge more.

4. Price testing: Experiment with different price points. You could:

    - Offer different tiers (e.g., basic roast, premium roast with extra features)
    - Use A/B testing on your website with different prices
    - Gradually increase prices and monitor demand

...`;

  return (
    <div className="max-w-6xl mx-auto my-20 px-4">
      <div className="mb-12 space-x-4 flex justify-center items-center">
        <img
          src="/assets/chatgpt-logo.png"
          alt="chatgpt logo"
          loading="lazy"
          className="w-32 md:w-64"
        />
        <span className="text-ivory-400 text-2xl md:text-4xl font-bold">
          vs
        </span>
        <div className="w-32 md:w-64 -translate-y-[2px] space-x-1 flex items-center">
          <img
            src="/assets/favicon.svg"
            alt="buildpad icon"
            loading="lazy"
            className="w-[30px] md:w-[60px]"
          />
          <span className="text-[22px] md:text-[44px] font-bold text-terracotta-500">
            Buildpad
          </span>
        </div>
      </div>
      <div className="bg-ivory-50 rounded-lg shadow-md p-6 mb-8">
        <h3 className="text-xl font-semibold mb-2">David's question:</h3>
        <p className="text-lg text-gray-700">
          "How do I find an optimal price for my service?"
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="p-3 md:p-6 bg-gradient-to-br from-ivory-150 to-ivory-250 rounded-lg shadow-md">
          <div className="mb-4 flex items-center">
            <img
              src="/assets/chatgpt-logo.png"
              alt="chatgpt vs buildpad"
              loading="lazy"
              className="w-24 md:w-32"
            />
            <span className="text-[18px] font-bold">:</span>
          </div>
          <MessageContent content={chatgptAnswer} isUser={false} />
        </div>

        <div className="p-3 md:p-6 bg-gradient-to-br from-ivory-150 to-ivory-250 rounded-lg shadow-md">
          <div className="mb-4 -translate-y-[2px] space-x-1 flex items-center">
            <img
              src="/assets/favicon.svg"
              alt="buildpad icon"
              loading="lazy"
              className="w-[26px] md:w-[37px]"
            />
            <span className="text-[17px] md:text-[21px] font-bold text-terracotta-500">
              Buildpad:
            </span>
          </div>
          <MessageContent content={buildpadAnswer} isUser={false} />
        </div>
      </div>
    </div>
  );
};

export default ChatGPTvsBuildpad;

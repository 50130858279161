import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import Banner from './components/Banner';
import LandingPage from './pages/LandingPage';
import NotFound from './pages/NotFound';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Invite from './pages/Invite';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LoadingScreen from './components/LoadingScreen';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const AppContent = () => {
  const location = useLocation();
  const publicPaths = [
    '/',
    '/signup',
    '/signin',
    '/invite',
    '/terms',
    '/privacy',
    '/progress',
  ];

  if (
    publicPaths.some((path) => location.pathname === path) ||
    location.pathname.startsWith('/invite/')
  ) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/invite/:token" element={<Invite />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <AuthenticatedApp />
    </Suspense>
  );
};

const App = () => {
  return (
    <div className="h-screen flex flex-col bg-ivory-200 text-charcoal-500">
      <Banner />
      <div className="relative flex-grow flex overflow-hidden">
        <Router>
          <AppContent />
        </Router>
      </div>
    </div>
  );
};

export default App;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api, withTokenRefresh } from '../api/axios';

const Invite = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const handleInvitation = withTokenRefresh(
      () => api.get(`/project/invite/accept/${token}`),
      (response) => {
        if (response.status === 200) {
          const projectId = response.data.project_id;
          navigate(`/dashboard?invite=${projectId}`);
        }
      },
      (error) => {
        console.error('Failed to accept invitation:', error);
        navigate('/signup');
      }
    );

    handleInvitation();
  }, [navigate, token]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );
};

export default Invite;

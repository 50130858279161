import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import ComparisonSection from '../components/ComparisonSection';
import Features from '../components/Features';
import ChatGPTvsBuildpad from '../components/ChatGPTvsBuildpad';
import SecuritySection from '../components/SecuritySection';
import Footer from '../components/Footer';
import WhoThrivesSection from '../components/WhoThrivesSection';
import FAQ from '../components/FAQ';
import FinalCta from '../components/FinalCta';
import LastJoined from '../components/LastJoined';

const LandingPage = () => {
  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-ivory-50 to-ivory-150 overflow-y-auto">
      <NavBar home={true} />
      <div className="container px-2">
        <div className="h-[65vh] my-10 space-y-10 flex flex-col justify-center items-center">
          <h1 className="text-center text-5xl md:text-6xl font-bold">
            Build products that people actually want
          </h1>
          <p className="text-center text-xl md:text-2xl text-charcoal-350">
            Your business building platform for the entire entrepreneurial
            journey, from idea to successful execution
          </p>
          <div className="flex flex-col justify-center items-center">
            <Link
              to="/signup"
              className="px-10 py-4 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-lg transition duration-200 ease-in-out"
            >
              Try Buildpad free
            </Link>
            <div className="mt-6">
              <LastJoined />
            </div>
          </div>
        </div>
        <div className="rounded-[8px] border border-ivory-400 shadow-2xl overflow-hidden">
          <img
            src="/assets/buildpad-dashboard.webp"
            alt="buildpad-dashboard"
            className="scale-[100.5%]"
          />
        </div>
        <div className="my-40 px-4">
          <ComparisonSection />
        </div>
        <div id="features" className="my-40 px-4">
          <Features />
        </div>
        <div className="my-40 px-4">
          <ChatGPTvsBuildpad />
        </div>
        <div className="my-40 px-4">
          <WhoThrivesSection />
        </div>
        <div className="my-40 px-4">
          <SecuritySection />
        </div>
        <div className="my-40 px-4">
          <FAQ />
        </div>
        <div className="my-40 px-4">
          <FinalCta />
        </div>
        <div className="mt-28 px-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

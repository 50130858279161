import React from 'react';
import { Link } from 'react-router-dom';

const UserTypeCard = ({ imageSrc, title, description }) => (
  <div className="relative w-full max-w-xs mx-auto mb-12 md:mb-0 md:w-64 h-[250px] p-6 flex flex-col justify-end items-center bg-gradient-to-br from-ivory-200 to-ivory-300 rounded-lg text-center">
    <img
      src={imageSrc}
      alt={title}
      loading="lazy"
      className="absolute -top-6 md:-top-16 h-36 md:h-36"
    />
    <h3 className="text-lg md:text-xl font-bold text-charcoal-500 mb-2">
      {title}
    </h3>
    <p className="text-sm md:text-base text-charcoal-400">{description}</p>
  </div>
);

const WhoThrivesSection = () => {
  const userTypes = [
    {
      imageSrc: '/assets/lego-batman.png',
      title: 'Indie hackers',
      description:
        'Ideal for solo developers and creators ready to turn their coding skills into profitable ventures',
    },
    {
      imageSrc: '/assets/lego-superman.png',
      title: 'Solopreneurs',
      description:
        'Perfect for ambitious individuals looking to build and scale their business ideas independently',
    },
    {
      imageSrc: '/assets/lego-avengers.png',
      title: 'Small teams',
      description:
        'Empower your startup crew with collaborative tools and guidance to accelerate your business growth',
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-4">
      <h2 className="mb-12 md:mb-28 text-3xl md:text-4xl lg:text-5xl font-bold text-center text-charcoal-500">
        Who thrives with Buildpad
      </h2>
      <div className="md:mb-12 flex flex-col md:flex-row justify-center">
        {userTypes.map((userType, index) => (
          <UserTypeCard key={index} {...userType} />
        ))}
      </div>
      <div className="md:mt-10 flex justify-center">
        <Link
          to="/signup"
          className="px-6 md:px-8 py-3 md:py-4 text-sm md:text-lg text-ivory-50 bg-charcoal-500 hover:bg-charcoal-700 rounded-lg transition duration-200 ease-in-out"
        >
          Discover your entrepreneurial potential
        </Link>
      </div>
    </div>
  );
};

export default WhoThrivesSection;

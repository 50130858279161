import React from 'react';
import { FaLock, FaShieldAlt, FaUserSecret } from 'react-icons/fa';

const SecuritySection = () => {
  return (
    <div
      id="security"
      className="py-16 bg-gradient-to-br from-ivory-250 to-ivory-300 rounded-lg"
    >
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-10 text-charcoal-500">
          Your business data is protected
        </h2>

        <div className="grid md:grid-cols-3 gap-8 mb-12">
          <SecurityFeature
            icon={<FaLock className="text-4xl text-terracotta-500" />}
            title="Database encryption"
            description="All sensitive business data is encrypted in our databases, ensuring your information remains confidential and secure at rest."
          />
          <SecurityFeature
            icon={<FaShieldAlt className="text-4xl text-terracotta-500" />}
            title="Secure access controls"
            description="We use strong authentication methods, including hardware security keys, to protect access to our servers and your data."
          />
          <SecurityFeature
            icon={<FaUserSecret className="text-4xl text-terracotta-500" />}
            title="Privacy commitment"
            description="We are committed to protecting your privacy and handling your data with the utmost care and respect."
          />
        </div>

        <div className="text-center">
          <p className="text-lg text-charcoal-400">
            At Buildpad, we prioritize the security of your business data. We
            implement industry-standard encryption for data storage and robust
            access controls to ensure your information stays protected.
          </p>
        </div>
      </div>
    </div>
  );
};

const SecurityFeature = ({ icon, title, description }) => {
  return (
    <div className="text-center">
      <div className="mb-4 flex justify-center">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-charcoal-500">{title}</h3>
      <p className="text-charcoal-400">{description}</p>
    </div>
  );
};

export default SecuritySection;

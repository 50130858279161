import React, { useEffect, useState } from 'react';
import {
  FaLightbulb,
  FaSitemap,
  FaChartLine,
  FaRobot,
  FaUsers,
} from 'react-icons/fa';

const featureList = [
  {
    icon: <FaLightbulb className="text-3xl md:text-5xl" />,
    label: 'Idea generation',
    color: 'text-yellow-500',
    hoverColor: 'group-hover:text-yellow-500',
    description: 'Validate your ideas using real world data.',
    image: '/assets/idea-generation-feature.png',
  },
  {
    icon: <FaSitemap className="text-3xl md:text-5xl" />,
    label: 'Structured process',
    color: 'text-blue-500',
    hoverColor: 'group-hover:text-blue-500',
    description:
      'Follow a step-by-step process to turn your idea into a viable business.',
    image: '/assets/structured-process-feature.png',
  },
  {
    icon: <FaUsers className="text-3xl md:text-5xl" />,
    label: 'Collaboration',
    color: 'text-red-500',
    hoverColor: 'group-hover:text-red-500',
    description:
      'Work on your project together, with AI helping the whole team at once.',
    image: '/assets/collaboration-feature.png',
  },
  {
    icon: <FaChartLine className="text-3xl md:text-5xl" />,
    label: 'Progress tracking',
    color: 'text-green-500',
    hoverColor: 'group-hover:text-green-500',
    description:
      'Monitor your progress and stay on track with your business goals.',
    image: '/assets/progress-tracking-feature.png',
  },
  {
    icon: <FaRobot className="text-3xl md:text-5xl" />,
    label: 'AI guidance',
    color: 'text-purple-500',
    hoverColor: 'group-hover:text-purple-500',
    description:
      'Buildpad uses a central context bank so the AI learns about your project from every interaction.',
    image: '/assets/ai-guidance-feature.png',
  },
];

const FeatureItem = ({ icon, label, color, hoverColor, isActive, onClick }) => (
  <div
    className={`group mb-4 md:mb-0 space-y-2 flex flex-col items-center rounded-lg cursor-pointer transition duration-200 ease-in-out`}
    onClick={onClick}
  >
    <div className={`${isActive ? color : `text-charcoal-250 ${hoverColor}`}`}>
      {icon}
    </div>
    <span
      className={`text-xs md:text-lg font-medium ${
        isActive ? color : `text-charcoal-250 ${hoverColor}`
      }`}
    >
      {label}
    </span>
  </div>
);

const Features = () => {
  const [activeFeature, setActiveFeature] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(src);
        img.onerror = reject;
      });
    };

    const loadAllImages = async () => {
      try {
        const imagePromises = featureList.map((feature) =>
          loadImage(feature.image)
        );
        const loadedSrcs = await Promise.all(imagePromises);
        const loadedObj = loadedSrcs.reduce(
          (acc, src) => ({ ...acc, [src]: true }),
          {}
        );
        setLoadedImages(loadedObj);
      } catch (error) {
        console.error('Failed to load some images', error);
      }
    };

    // Use requestIdleCallback to load images when the browser is idle
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => loadAllImages());
    } else {
      // Fallback for browsers that don't support requestIdleCallback
      setTimeout(loadAllImages, 1000);
    }
  }, []);

  return (
    <div className="max-w-4xl mx-auto">
      <div className="md:mb-8 space-x-4 md:space-x-10 flex flex-wrap justify-center items-center">
        {featureList.map((feature, index) => (
          <FeatureItem
            key={index}
            {...feature}
            isActive={activeFeature === index}
            onClick={() => setActiveFeature(index)}
          />
        ))}
      </div>
      <div className="text-center mb-8">
        <p className="text-sm md:text-lg font-medium">
          {featureList[activeFeature].description}{' '}
          <span
            className={`${featureList[activeFeature].color} hover:underline cursor-pointer`}
          >
            Learn more →
          </span>
        </p>
      </div>
      <div className="h-[185px] md:h-[500px] border border-ivory-400 rounded-lg overflow-hidden shadow-2xl relative">
        {featureList.map((feature, index) => (
          <img
            key={index}
            src={feature.image}
            alt={feature.label}
            className={`w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-300 ${
              activeFeature === index ? 'opacity-100' : 'opacity-0'
            } ${loadedImages[feature.image] ? '' : 'invisible'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Features;

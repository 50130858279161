import React from 'react';
import { Link } from 'react-router-dom';
// import {
//   FaWhatsapp,
//   FaYoutube,
//   FaInstagram,
//   FaFacebookF,
//   FaLinkedinIn,
//   FaTwitter,
// } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="text-charcoal-500 py-10">
      <div className="max-w-6xl mx-auto px-4">
        {/* <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-semibold mb-4 text-terracotta-500">
              Programs
            </h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  Idea Validation
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  MVP Development
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  Growth Strategies
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4 text-terracotta-500">
              Services
            </h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  AI Guidance
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  Progress Tracking
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  Collaboration Tools
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4 text-terracotta-500">
              Contact
            </h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="hover:text-terracotta-500 transition-colors"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4 text-terracotta-500">
              Newsletter
            </h3>
            <form className="flex flex-col space-y-2">
              <input
                type="email"
                placeholder="Email Address"
                className="p-2 rounded bg-ivory-50 focus:outline-none focus:ring-2 focus:ring-terracotta-500"
              />
              <button
                type="submit"
                className="bg-terracotta-500 text-ivory-50 py-2 px-4 rounded hover:bg-terracotta-600 transition duration-200 ease-in-out"
              >
                Subscribe
              </button>
            </form>
            <div className="flex space-x-4 mt-4">
              <FaWhatsapp className="text-2xl hover:text-terracotta-500 cursor-pointer transition-colors" />
              <FaYoutube className="text-2xl hover:text-terracotta-500 cursor-pointer transition-colors" />
              <FaInstagram className="text-2xl hover:text-terracotta-500 cursor-pointer transition-colors" />
              <FaFacebookF className="text-2xl hover:text-terracotta-500 cursor-pointer transition-colors" />
              <FaLinkedinIn className="text-2xl hover:text-terracotta-500 cursor-pointer transition-colors" />
              <FaTwitter className="text-2xl hover:text-terracotta-500 cursor-pointer transition-colors" />
            </div>
          </div>
        </div> */}
        <div className="border-t-[0.5px] border-charcoal-400 pt-6 flex flex-col">
          <div className="space-y-2 lg:space-y-0 flex flex-col lg:flex-row justify-between items-start lg:items-center">
            <div className="">
              <Link to="/" className="space-x-1 flex items-center">
                <img
                  src="/assets/favicon.svg"
                  alt="buildpad icon"
                  className="w-[40px]"
                />
                <h1 className="text-[30px] font-bold text-terracotta-500">
                  Buildpad
                </h1>
              </Link>
            </div>
            <div className="space-x-4">
              <Link
                to="/terms"
                className="text-charcoal-350 hover:text-charcoal-400 transition duration-200 ease-in-out"
              >
                Terms
              </Link>
              <Link
                to="/privacy"
                className="text-charcoal-350 hover:text-charcoal-400 transition duration-200 ease-in-out"
              >
                Privacy
              </Link>
            </div>
          </div>
          <div className="mt-6 text-sm text-charcoal-300">
            © 2024 Buildpad. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const refreshToken = async () => {
  try {
    const response = await api.post('/auth/refresh');
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const withTokenRefresh =
  (apiCall, onSuccess, onFailure) =>
  async (...args) => {
    try {
      const response = await apiCall(...args);
      onSuccess(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refreshSuccessful = await refreshToken();
        if (refreshSuccessful) {
          try {
            const response = await apiCall(...args);
            onSuccess(response);
          } catch (secondError) {
            console.error('Operation failed after token refresh:', secondError);
            onFailure(secondError);
          }
        } else {
          console.error('Token refresh failed');
          onFailure(error);
        }
      } else {
        console.error('Operation failed:', error);
        onFailure(error);
      }
    }
  };

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavBar = ({ home }) => {
  return (
    <nav className="px-2 md:px-10 py-4 flex justify-between items-center text-charcoal-500">
      <div className="hidden w-1/3 space-x-10 md:flex items-center">
        {home && (
          <>
            <HashLink
              to="#features"
              className="text-lg hover:text-terracotta-500 transition duration-200 ease-in-out"
            >
              Features
            </HashLink>
            <HashLink
              to="#security"
              className="text-lg hover:text-terracotta-500 transition duration-200 ease-in-out"
            >
              Security
            </HashLink>
            <HashLink
              to="#faq"
              className="text-lg hover:text-terracotta-500 transition duration-200 ease-in-out"
            >
              FAQ
            </HashLink>
          </>
        )}
      </div>
      <div className="flex items-center">
        <Link to="/" className="space-x-1 flex items-center">
          <img
            src="/assets/favicon.svg"
            alt="buildpad icon"
            className="w-[40px] md:w-[60px]"
          />
          <h1 className="text-[33px] md:text-[44px] font-bold text-terracotta-500">
            Buildpad
          </h1>
        </Link>
      </div>
      <div className="md:w-1/3 space-x-10 flex justify-end items-center">
        <Link
          to="/signin"
          className="hidden md:block text-lg hover:text-terracotta-500 transition duration-200 ease-in-out"
        >
          Sign in
        </Link>
        <Link
          to="/signup"
          className="min-w-[120px] px-4 md:px-10 py-2 md:py-3 md:text-lg text-terracotta-500 hover:text-ivory-50 hover:bg-terracotta-500 border-2 border-terracotta-500 rounded-md transition duration-200 ease-in-out"
        >
          Get started
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;

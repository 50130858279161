import React from 'react';
import { Link } from 'react-router-dom';

const FinalCta = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-2 md:px-8">
        <div className="text-center">
          <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900">
            Ready to build your next big idea?
          </h2>
          <p className="mt-4 text-xl md:text-2xl text-gray-600">
            Join Buildpad today and turn your vision into reality.
          </p>
          <div className="mt-12">
            <Link
              to="/signup"
              className="px-10 py-4 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-lg transition duration-200 ease-in-out"
            >
              Get started for free
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalCta;
